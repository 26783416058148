<script setup>
import { ref, watchEffect, nextTick } from 'vue';
import { defineProps, defineEmits } from 'vue';

const chatWindow = ref(null);
const messagesEnd = ref(null);
const isChatOpen = ref(false);
const userMessage = ref('');
const messages = ref([]);
const isSending = ref(false);

const props = defineProps({todos:Array, id:Number});
const emit = defineEmits(['updateTodos']);

// FAQ 데이터를 messages에 추가
messages.value.push({ role: 'system', content: "응답은 가능한 짧게하며, 사용자가 따로 요청하기 전까진 한국어로 대답합니다." });
// messages.value.push({ role: 'system', content: "사용자가 질문 외의 것을 물어본다면 '관련된 질문만 해주세요'라고 대답한다." });
messages.value.push({ role: 'system', content: "사용자가 질문이 Q: {내용} 과 일치해야 A에 대한 대답을 내놓고 Q: {내용}과 다른 내용이라면 대답 못 한걸로 판단합니다." });
props.todos.forEach(todo => {
  messages.value.push({
    role: 'system',
    content: `Q: ${todo.board_title}, A: ${todo.answer}`
  });
});
messages.value.push({ role: 'system', content: "사용자가 질문을 대답하지 못한다면 신청할 수 있게 합니다. 신청버튼은 a태그, 클래스는 btn btn-info rounded-pill, 함수명은 QnaInsert, 첫번쨰 파라미터는 제목 두번째파라미터는 내용입니다." });


const clearMessages = () => {
  messages.value = [];
  messages.value.push({ role: 'system', content: "응답은 가능한 짧게하며, 사용자가 따로 요청하기 전까진 한국어로 대답합니다." });
  messages.value.push({ role: 'system', content: "사용자가 질문이 Q: {내용} 과 일치해야 A에 대한 대답을 내놓고 Q: {내용}과 다른 내용이라면 대답 못 한걸로 판단합니다." });
  props.todos.forEach(todo => {
    messages.value.push({
      role: 'system',
      content: `Q: ${todo.board_title}, A: ${todo.answer}`
    });
  });
  messages.value.push({ role: 'system', content: "사용자가 질문을 대답하지 못한다면 신청할 수 있게 합니다. 신청버튼은 a태그, 클래스는 btn btn-info rounded-pill, 함수명은 QnaInsert, 첫번쨰 파라미터는 제목 두번째파라미터는 내용입니다." });

};
const toggleChat = () => {
  isChatOpen.value = !isChatOpen.value;
  if (isChatOpen.value) nextTick(scrollToBottom);
};

const sendMessage = async () => {
  if (userMessage.value.trim() === '' || isSending.value) return;

  // 메시지를 보내는 동안 버튼 비활성화
  isSending.value = true;

  // 사용자 입력 메시지를 추가
  messages.value.push({ role: 'user', content: userMessage.value });
  await nextTick(scrollToBottom);

  try {
    // OpenAI API 호출
    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer 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'
      },
      body: JSON.stringify({
        model: 'gpt-4o',
        messages: messages.value,
        /*functions: [
            {
              name: 'QnaInsert',
              parameters: {
                type: 'object',
                properties: {
                  title: {'type': 'string', description: '질문 제목'},
                  content: {'type': 'string', description: '질문 내용'},
                },
                'required': ['title', 'content']
              },
              'function_call': 'auto'
            }
        ]*/
      })
    });

    const data = await response.json();
    const aiResponse = data.choices[0].message.content;

    messages.value.push({ role: 'assistant', content: aiResponse });
    nextTick(scrollToBottom);
  } catch (error) {
    console.error('Error:', error);
  }

  userMessage.value = '';
  isSending.value = false;
};


const scrollToBottom = () => {
  if (messagesEnd.value) {
    messagesEnd.value.scrollIntoView({ block:'end', behavior: 'smooth' });
  }
  document.querySelector('.form-control')?.focus();
};

watchEffect(() => {
  nextTick(scrollToBottom);
});

// eslint-disable-next-line no-unused-vars
window.QnaInsert = (title='', content='') => {
  emit('updateTodos', {
    id: props.id,
    board_title: title,
    board_note: content,
    comp_name: '삼안',
    emp_name: '김진선',
    answer: ''
  });
}
</script>
<template>
  <div>
    <!-- 채팅 아이콘 -->
    <div v-if="!isChatOpen" class="chat-icon" @click="toggleChat">
      <i class="bi bi-chat-dots" fill="currentcolor"></i>
    </div>
    <!-- 채팅 창 -->
    <transition name="chat-window-transition">
    <div v-if="isChatOpen" class="chat-window" ref="chatWindow">
      <div class="chat-header">
        <div>FAQ ChatBot</div>
        <div>
          <button @click="clearMessages" class="badge text-bg-secondary me-2">Clear</button>
          <button @click="toggleChat" class="btn-close"></button>
        </div>
      </div>
      <div class="chat-body" ref="chatWindow">
        <div v-for="(message, index) in messages" :key="index" :class="message.role">
          <div v-if="message.role === 'user'" class="user-message">{{ message.content }}</div>
          <div v-else-if="message.role === 'system'" class="d-hide"></div>
          <div v-else class="ai-message" v-html="message.content"></div>
        </div>
        <div ref="messagesEnd" class="d-hide"></div>
      </div>
      <div class="chat-footer">
        <input v-model="userMessage" type="text" class="form-control" placeholder="무엇이 궁금한가요?" @keyup.enter="sendMessage" :disabled="isSending">
        <button class="btn btn-primary" @click="sendMessage" :disabled="isSending">Send</button>
      </div>
    </div>
    </transition>
  </div>
</template>

<style scoped>
.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 32px;
  cursor: pointer;
  z-index: 1000; /* 다른 요소들 위에 오게 */
}

.chat-window {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 400px;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000; /* 다른 요소들 위에 오게 */
  display: flex;
  flex-direction: column;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  //cursor: nwse-resize; /* 커서 모양 변경 */
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.chat-footer {
  display: flex;
  border-top: 1px solid #ccc;
  position: fixed;
  bottom: 0px;
  margin-left: -12px;
  padding: 10px 10px;
}

.user-message {
  text-align: right;
  margin-bottom: 10px;
  background-color: #e0e0e0; /* 밝은 회색 배경 */
  padding: 8px;
  border-radius: 5px;
}

.ai-message {
  text-align: left;
  margin-bottom: 10px;
  background-color: #d1ffd1; /* 다른 색 배경 (예: 연한 녹색) */
  padding: 8px;
  border-radius: 5px;
}

.chat-window-transition-enter-active,
.chat-window-transition-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.chat-window-transition-enter-from {
  opacity: 0;
  transform: translateY(100%);
}
.chat-window-transition-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.chat-window-transition-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.chat-window-transition-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>