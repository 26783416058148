<script setup>
import axios from 'axios';

const controlDevice = async (command) => {
  try {
    const response = await axios.post('http://localhost:3000/api/control', {
      deviceId: 'your_device_id',  // 실제 Tapo P100의 ID를 입력하세요.
      command: command
    });
    console.log(response.data.message);
  } catch (err) {
    alert(err);
  }
}
</script>

<template>
  <main class="container">
    <button class="btn btn-primary" @click="controlDevice('on')">Turn On</button>
    <button class="btn btn-danger" @click="controlDevice('off')">Turn Off</button>
  </main>
</template>