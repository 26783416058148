import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import { BootstrapVueNext } from 'bootstrap-vue-next';

const app = createApp(App)
    .use(router)
    .use(BootstrapVueNext);

// Middleware-like function to set headers
router.beforeEach((to, from, next) => {
  if (typeof window !== 'undefined') {
    // These headers would normally be set on the server side
    document.headers = {
      'Cache-Control': 'no-cache, no-store',
      'Pragma': 'no-cache',
      'Expires': '0',
    };
  }
  next();
});

router.isReady().then(() => {
  app.mount('#app');
});