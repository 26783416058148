<script setup>
import {ref, provide} from "vue";
import ChatComponent from '../components/ChatBot.vue';

let id = 0
const todos = ref([
    { id: id++, board_title:'이메일 패스워드를 잃어버렸어요.', board_note:'접속이 안됩니다.', comp_name:'삼안', emp_name:'테스터', answer: '인사총무팀 A과장 혹은 B사원에게 문의바랍니다.'},
    { id: id++, board_title:'프린터가 이상해요.', board_note:'인쇄할때 엉뚱한게 인쇄됩니다.', comp_name:'삼안', emp_name:'김삼안', answer: '인사총무팀 C사원에게 문의바랍니다.'},
    { id: id++, board_title:'ERP계정을 까먹었어요.', board_note:'몰라요.', comp_name:'삼안', emp_name:'박삼안', answer: '인사총무팀 A과장 혹은 B사원에게 문의바랍니다.'},
    { id: id++, board_title:'eg-bim 설치가 안되요', board_note:'설치할때 오류나요.', comp_name:'삼안', emp_name:'최삼안', answer: 'EG-BIM Q&A 게시판에 문의해주시기 바랍니다.'},
])

const addTodo = (newTodo) => {
  todos.value.push(newTodo);
  id++;
};

provide('faqData', todos.value);
</script>

<template>
  <main class="container">
    <div class="my-3 p-3 bg-body rounded shadow-sm">
      <ul class="list-group">
        <li v-for="todo in todos" :key="todo.id" class="list-group-item d-flex flex-column flex-md-row justify-content-between align-items-start">
            <div class="me-auto">
                <div class="fw-bold">Q: {{ todo.board_title }}</div>
                <div class="d-none d-md-block">{{ todo.board_note }}</div>
            </div>
            <div v-if="todo.answer != ''" class="ms-auto me-2 fw-bold">A: {{ todo.answer }}</div>
            <div v-else class="ms-auto me-2 fw-bold">A: 답변대기중</div>
            <span class="badge bg-primary rounded-pill me-1 d-none d-md-inline">{{ todo.comp_name }}</span>
            <span class="badge bg-secondary rounded-pill d-none d-md-inline">{{ todo.emp_name }}</span>
        </li>
      </ul>
    </div>
    <div class="dropdown position-fixed bottom-0 end-0 mb-3 me-3">
      <ChatComponent :todos="todos" :id="id" @updateTodos="addTodo" />
    </div>
  </main>
</template>