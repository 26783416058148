<script setup>
import {ref,onMounted} from "vue";

const menuOpen = ref(false)
const links = ref({
  '/': 'Dashboard',
  '/faq': 'Q&A',
  '/chat': 'Javis',
  '/tplink': 'IoT',
});

const toggleNav = () => {
  menuOpen.value = !menuOpen.value
};

onMounted(() => {
  const nav = document.querySelector('.navbar');
  console.log('Header Component Mounted', nav);
  if (nav) {
      const navHeight = nav.offsetHeight;
      document.documentElement.style.setProperty('--nav-height', `${navHeight}px`);
  }
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
</script>

<template>
  <nav class="navbar navbar-expand-xl fixed-top" style="background-color: #222831" aria-label="Main navigation">
    <div class="container-fluid">
      <img src="@/assets/JavisLogo.png" style="position: absolute;height: 100%;padding: 5px 0;">
      <a class="navbar-brand text-light" style="padding-left:55px" href="#">{{ links[$route.path] }}</a>

      <button class="navbar-toggler p-0 border-0" @click="toggleNav">
        <i class="bi bi-list text-light fs-2"></i>
      </button>
      <div class="navbar-collapse offcanvas-collapse" :class="{open: menuOpen}">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item menuList" v-for="(label, link) in links" :key="link">
            <router-link class="nav-link text-light" :to="link" :class="{ active: $route.path === link }" @click="toggleNav">
              {{ label }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>