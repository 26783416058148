<script setup>
import { ref, defineEmits } from 'vue';

const video = ref(null);
const stream = ref(null);
const canvas = ref(null);
const previewImage = ref(null);
const isCameraOpen = ref(false);
const emit = defineEmits(['updateMsg']);
const videoInfo = ref('대기중');

const startCamera = async () => {
  try {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      stream.value = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { exact: 'environment' } }
      });
      video.value.srcObject = stream.value;
      video.value.onloadedmetadata = () => {
        const videoWidth = video.value.videoWidth;
        const videoHeight = video.value.videoHeight;
        canvas.value.width = videoWidth;
        canvas.value.height = videoHeight;
        videoInfo.value = `Width: ${videoWidth} / Height: ${videoHeight}`;
        video.value.play();
      };
    } else {
      alert('This browser does not support access to the camera.');
    }
  } catch (error) {
    console.error('Error accessing camera:', error);
    alert('This browser does not support access to the camera.');
  }
};
const takePicture = () => {
  const context = canvas.value.getContext('2d');
  context.drawImage(video.value, 0, 0, canvas.value.width, canvas.value.height);
  const dataURL = canvas.value.toDataURL('image/png');
  previewImage.value = dataURL;
  sendPicture(dataURL);
};

const handleFileSelect = async (event) => {
  const file = event.target.files[0];

  const reader = new FileReader();
  reader.onload = (e) => {
    const dataURL = e.target.result;
    resizeImage(dataURL, 1024, (resizedDataURL) => {
      previewImage.value = resizedDataURL;
      sendPicture(resizedDataURL);
    });
  };
  if (file) {
    reader.readAsDataURL(file);
  }
};

const sendPicture = async (dataURL) => {
  try {
    const imageData = dataURL.split(',')[1];

    emit('updateMsg', 'data:image/png;base64,' + imageData);
    await fetch('https://pro.mel0ng.kr/api.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ apiName: 'sendPicture', msg: 'data:image/png;base64,' + imageData })
    });
    await toggleCamera();

  } catch (error) {
    console.error('Error sending picture:', error);
  }
};

const toggleCamera = async () => {
  isCameraOpen.value = !isCameraOpen.value;
  if (isCameraOpen.value && !stream.value) {
    await startCamera();
  }
};

const resizeImage = (dataURL, maxWidth, callback) => {
  const img = new Image();
  img.onload = () => {
    let width = img.width;
    let height = img.height;

    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);

    const resizedDataURL = canvas.toDataURL('image/jpeg', 0.7);
    callback(resizedDataURL);
  };
  img.src = dataURL;
};
</script>
<template>
  <div>
    <!-- 촬영 버튼 -->
    <button type="button" class="btn btn-info me-2" @click="toggleCamera">
      <i class="bi bi-camera text-light"></i>
    </button>

    <!-- 카메라 창 -->
    <transition name="camera-window-transition">
      <div v-if="isCameraOpen" class="camera-window">
        <div class="camera-header">
          <div>AI 사진인식</div>
          <div>
            <button @click="toggleCamera" class="btn-close"></button>
          </div>
        </div>
        <div id="video-container">
          <video ref="video" id="video" autoplay></video>
        </div>
        <div class="text-center">
          <button id="capture-button" class="btn btn-primary" @click="takePicture">
            <i class="bi bi-camera"></i> 촬영
          </button>
          <button id="album-button" class="btn btn-secondary ms-3">
            <label for="album" class="mb-0">
              <i class="bi bi-folder"></i> 앨범
            </label>
          </button>
          <input type="file" id="album" accept="image/*" @change="handleFileSelect" class="form-control mb-2 d-none" />
        </div>
        <canvas ref="canvas" width="640" height="480" style="display:none;"></canvas>
        <p>{{videoInfo}}</p>
        <div v-if="previewImage" id="preview-container">
          <h3>미리보기</h3>
          <img :src="previewImage" alt="Preview" id="preview-image">
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
@import "./style/Camera.css";
</style>